import styled from "styled-components";

export const Message = styled.div` 
position: relative;
  .message__sender-name {
    margin: ${({option}) => option === 'doctor' ? '10px 0 10px auto' : '10px auto 10px 0' };
    color: #8E8D94;
    font-family: "Nunito Sans";
    font-size: 13px;
    width: max-content;
    margin-bottom: 3px;
  }
  .message {
    font-size: 0.75rem;
    width: fit-content;
    max-width: 500px;
    white-space: pre-line;
    /* margin-bottom: ${({time}) => time ? '2px' : '8px' }; */
    &--patient {
      margin-left: auto;
      padding: 14.505px 10px;
      border-radius: 20px 20px 10px 20px;
      border: #1F1F52;
      background: #1F1F52;
      color: #FFF;
      font-family: "Nunito Sans";
      font-size: 16px;
    }
    &--reciever {
      padding: 14.505px 10px;
      border-radius: 20px 20px 20px 10px;
      border: 0.806px solid #E8E7F4;
      background: #E8E7F4;
      color: #36369B;
      font-family: "Nunito Sans";
      font-size: 16px;
    }
    a {
      text-decoration: underline;
    }
  }
  .message__time{
    margin: ${({option}) => option === 'doctor' ? '0 0 0 auto' : '0 auto 0 0' };
    width: max-content;
    font-family: "Open Sans";
    color: var(--text-toggle-gray);
    font-size: 8.5px;
    margin-bottom: 8px;
  }
  .message__tail--reciever {
    position: absolute;
    bottom: -10px;
    left: -10px;
  }
  .message__tail--patient {
    position: absolute;
    bottom: -10px;
    right: -9px;
  }
`; 


