import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListOfAlerts.Style";
import { Actions } from "../../../../../utils/actionsIds";

// Pipes
import * as P from "../../../../../utils/pipes";
import Popup from "reactjs-popup";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-alerts.svg`;
const alertsEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/list-alerts-empty.svg`;

function ListOfAlerts({
}) {

  const [filterAZ, setFilterAZ] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterAlertLevel, setFilterAlertLevel] = useState(null);
  const [filterAlertType, setFilterAlertType] = useState(null);
  const [commentsOnly, setCommentsOnly] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation("global");



  return (
    <S.ListOfAlerts>
      <div className="table-container">
        <table className="suggestion-table">
          <thead className="suggestion-table__head">
            <tr>
              <th>
                {
                  true 
                  ? <i className="check-patient icon zaia-i-checkbox-off"></i>
                  : <i className="check-patient icon zaia-i-checkbox-on"></i>
                }
              </th>
              <th className="filter filter--patient-name"> 
                <div className="filter__label">{t("aiNavigator.filters.orderPatients")}:</div>
                <div className="filter__space">
                  <button 
                    className={`patient-order ${filterAZ !== null && "patient-order--actived"}`}
                    onClick={() => setFilterAZ(
                      filterAZ === null ? true : (filterAZ === false ? null : false)
                    )}
                  >
                    <i className={`patient-order__icon icon zaia-i-filters ~iconchange__text ${filterAZ !== null && "patient-order__icon--actived"}`}></i>
                    <div className="patient-order__text">
                      {t("aiNavigator.filters.orderAZ")} <b>
                        {
                          filterAZ === false 
                          ? "Z-A"
                          : "A-Z"
                        }
                      </b>
                    </div>
                  </button>
                </div>
              </th>
              <th className="filter filter--date">
                <div className="filter__label">{t("aiNavigator.filters.orderEnrrollDate")}:</div>
                <div className="filter__space">
                  <button 
                    className={`patient-order ${filterDate !== null && "patient-order--actived"}`}
                    onClick={() => setFilterDate(
                      filterDate === null ? true : (filterDate === false ? null : false)
                    )}
                  >
                    <div className="patient-order__text">
                      {
                        filterDate === false
                        ? t("aiNavigator.filters.oldest")
                        : filterDate === true
                          ? t("aiNavigator.filters.mostRecent")
                          : t("aiNavigator.filters.tapToOrder")
                      }
                    </div>
                  </button>
                </div>
              </th>
              <th className="filter filter--program">
                <div className="filter__label">{t("aiNavigator.filters.filterByProgram")}</div>
                <div className="filter__space">
                  <Popup
                    trigger={
                      <button className={`program-filter ${filterDate !== null && "program-filter--actived"}`}>
                        <i className="program-filter__icon icon zaia-i-especilidad-sort"></i>
                        <div className="program-filter__text">
                          ~Nivel del alerta
                        </div>
                      </button>
                    }
                    closeOnDocumentClick
                    keepTooltipInside
                    position={["bottom center"]}
                    nested
                  >
                    {
                      close => (
                        <FilterAlerts
                          close={close}
                          filterAlertLevel={filterAlertLevel}
                          setFilterAlertLevel={setFilterAlertLevel}
                          filterAlertType={filterAlertType}
                          setFilterAlertType={setFilterAlertType}
                          commentsOnly={commentsOnly}
                          setCommentsOnly={setCommentsOnly}
                        />
                      )
                    }
                  </Popup>                   
                </div>
              </th>
              <th className="filter filter--program">
                <div className="filter__label">{t("aiNavigator.filters.filterByProgram")}</div>
                <div className="filter__space">
                  <Popup
                    trigger={
                      <button className={`program-filter ${filterDate !== null && "program-filter--actived"}`}>
                        <i className="program-filter__icon icon zaia-i-especilidad-sort"></i>
                        <div className="program-filter__text">
                          ~tipo del alerta
                        </div>
                      </button>
                    }
                    closeOnDocumentClick
                    keepTooltipInside
                    position={["bottom center"]}
                    nested
                  >
                    {
                      close => (
                        <FilterAlerts
                          close={close}
                          filterAlertLevel={filterAlertLevel}
                          setFilterAlertLevel={setFilterAlertLevel}
                          filterAlertType={filterAlertType}
                          setFilterAlertType={setFilterAlertType}
                          commentsOnly={commentsOnly}
                          setCommentsOnly={setCommentsOnly}
                        />
                      )
                    }
                  </Popup>                   
                </div>
              </th>
              <th className="filter">
                <i className="filter__icon icon zaia-icono-menu"/>
                <div className="filter__label">~Descripción de alerta</div>
              </th>
              <th className="filter">
                <i className="filter__icon icon zaia-i-name-sort"/>
                <div className="filter__label">~Fecha</div>
              </th>
              <th></th>
            </tr>
          </thead>
          {
            false &&
            <div className="suggestion-table__reaction">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                widthImg="100px"
                text={t("globally.errorLoadingPatients")}
                sizeText="15px"
                widthText="240px"
              />
            </div>
          }
          {
            false &&
            <div className="suggestion-table__reaction">
              <ZaiaReaction
                widthImg="150px"
                zaiaImg={logoZaiaLoading}
              />  
            </div>
          }
          {
            false &&
            <div className="suggestion-table__reaction">
              <div className="reaction-empty">
                <img 
                  className="reaction-empty__img"
                  src={alertsEmpty}
                  alt=""
                />
                <div className="reaction-empty__text">No hay alertas para revisar por el momento</div>
                <button className="reaction-empty__button">
                  Revisar historial de alertas gestionadas
                </button>
              </div>
            </div>
          }
          <tbody className="suggestion-table__body">
            { 
              [1,2,3,4,5,6,7,8,9]?.map((item) => {
                return (
                  <tr 
                    onClick={
                      () => {}
                    }
                    key={item.id}
                    className="suggestion-table__item">
                    <td>
                      {
                        true
                        ? <i className="check-patient icon zaia-i-checkbox-on"></i>
                        : <i className="check-patient icon zaia-i-checkbox-off"></i>
                      }
                    </td>
                    <td>
                      <div className="patient-content">
                        <ZaiaImage
                          img={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThgMPDV54XVOYrK2qJwF4AEy_jLEac9pVh8Q&s"}
                          alt=""
                          size={40}
                          rounded={true}
                        />
                        <div className="patient-content__info">
                          <div className="patient-content__info-name-psa">
                            {`${"Cameron"} ${"Williamson"}`}
                          </div>
                          {
                            true &&
                            <div className="patient-content__extra-option">
                              <i className="patient-content__extra-option-icono icon zaia-i-especilidad-sort"></i>
                              <div className="zaia-i-especilidad-sort"></div>
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="alert-level">
                        <i className="alert-level__icon icon zaia-i-tooltip"></i>
                        <div className="alert-level__text">{"~Alerta Severa"}</div>
                      </div>
                    </td>
                    <td>
                      <div className="alert-type">
                          <i className={`alert-type__icon icon ${"zaia-icono-sintomas"}`}></i>
                          <div className="alert-type__text">{"~Síntoma"}</div>
                        </div>
                      </td>
                    <td>
                      {
                        true &&
                        <div className="alert-description">
                          <div className="alert-description__text">
                            {"~El paciente ha presentado dolor de cabeza"}
                          </div>
                        </div>
                      }
                      {
                        false &&
                        <div className="alert-description">
                          <div className="alert-description__icon icon zaia-i-tooltip"></div>
                          <div className="alert-description__text">
                            <b>{"~Vomito"}</b> {"~Muy severo"}
                          </div>
                        </div>
                      }
                      {
                        false &&
                        <div className="alert-description">
                          <div className="alert-description__icon icon zaia-i-tooltip"></div>
                          <div className="alert-description__text">
                           {"~Temperatura"}  <b>{"~35 ℃"}</b>
                          </div>
                        </div>
                      }
                    </td>
                    <td>
                      <div className="alert-date">
                        {"~Feb 11, 2014"}
                      </div>
                    </td>
                    <td>
                      <div className="alert-options">
                        <i className="alert-options__icon zaia-icono-dots-vertical"></i>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </S.ListOfAlerts>
  );
}


const FilterAlerts = ({ 
  close,
  filterAlertLevel,
  setFilterAlertLevel,
  filterAlertType,
  setFilterAlertType,
  commentsOnly,
  setCommentsOnly
}) => {

  return(
    <S.FiltersAlerts>
      <div className="filters-box filters-box--left">
        <div className="filters-box__label">Seleccione nivel</div>
        <div className="filters-box__title">Nivel de alerta</div>
        <div className="filters-box__content">
          <div className="levels-list">
            <div className={`level-item ${true && "level-item--actived"}`}>
              <div className="level-item__point"></div>
              <div className="level-item__text">Todos</div>
              <div className="level-item__number">20</div>
            </div>
            {
              [1,2,3,4].map((item) => {
                return (
                  <div className={`level-item ${false && "level-item--actived"}`}>
                    <div className="level-item__point"></div>
                    <div className="level-item__text">Leve</div>
                    <div className="level-item__number">4</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="filters-box filters-box--right">
        <div className="filters-box__label">Seleccione tipo de alerta</div>
        <div className="filters-box__title">Tipo de alerta</div>
        <div className="filters-box__content">
          <div className="types-list">
            <div className="type-item">
              <div className="type-item__circle">
                <div className="type-item__circle-number">
                  20
                </div>
                <div className="type-item__circle-icon icon zaia-icono-home"></div>
              </div>
              <div className="type-item__label">Todos</div>
            </div>
            {
              [1,2,3,4].map((item) => {
                return (
                  <div className="type-item">
                    <div className="type-item__circle">
                      <div className="type-item__circle-number">
                        20
                      </div>
                      <div className="type-item__circle-icon icon zaia-icono-home"></div>
                    </div>
                    <div className="type-item__label">Síntomas</div>
                  </div>
                )
              })
            }
          </div>
          <div className="just-comments">
            <div className="just-comments__left">
              <div className="just-comments__icon icon zaia-i-message-outline"></div>
              <div className="just-comments__text">Sólo comentarios</div>
            </div>
            <div className="just-comments__right">
              <div className="just-comments__switch">
              <ZaiaToggleCircular
                id={'patient-alert-toggle'}
                color={"#27AE60"}
                toggle={(e) => {setCommentsOnly(e)}}
                active={commentsOnly}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.FiltersAlerts>
  )

}

export default ListOfAlerts;