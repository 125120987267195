import React, { useEffect, useRef, useState } from "react";
import * as S from "./GenerativeAI.style";
import * as P from "../../../utils/pipes";
import { useTranslation } from "react-i18next";

// Components
import ChatbotGeneralCase from "./components/cases/chatbotGeneralCase";
import ChatbotPatientCase from "./components/cases/chatbotPatientCase";
import ChatbotProcessCase from "./components/cases/chatbotProcessCase";
import ChatbotGuidanceCase from "./components/cases/chatbotGuidanceCase";
import { useStateValue } from "../../../contextAPI/StateProvider";
import useRecordNavigation from "../../../hooks/useRecordNavigation";
import { Actions } from "../../../utils/actionsIds";
import useDoctorData from "../../../hooks/useDoctorData";
import TransducerView from "../transducerView";

// assets
const florenceLogo = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/monaco-ai.svg`
const bgChatbot = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/chat-background.svg`

const ENV = process.env.REACT_APP_ENV;
const REACT_APP_DOCTOR_1_ID_DEMO = process.env.REACT_APP_DOCTOR_1_ID_DEMO;
const REACT_APP_DOCTOR_2_ID_DEMO = process.env.REACT_APP_DOCTOR_2_ID_DEMO;
const REACT_APP_DOCTOR_3_ID_DEMO = process.env.REACT_APP_DOCTOR_3_ID_DEMO;
const REACT_APP_DOCTOR_4_ID_DEMO = process.env.REACT_APP_DOCTOR_4_ID_DEMO;
const REACT_APP_DOCTOR_5_ID_DEMO = process.env.REACT_APP_DOCTOR_5_ID_DEMO;
const REACT_APP_DOCTOR_6_ID_DEMO = process.env.REACT_APP_DOCTOR_6_ID_DEMO;

function GenerativeAI({
  closeFunc,
  maxViewActived,
  setMaxViewActived,
  minimizeActived,
  setMinimizeActived,
  completeViewActived
}) {    
  const {t, i18n: { language }} = useTranslation("global");
  const { recordAction } = useRecordNavigation();

  const [{ 
    florenceView,
    florencePatient,
    florenceProcess,
    florencePartition
   }, dispatch] = useStateValue();


  const chatbotViewsDev = [
    {
      id: 1,
      name: t("chatbotAI.general.title"),
      description: t("chatbotAI.general.subtitle"),
      action: Actions.FLORENCE.CHAT_GENERAL,
      icon: "zaia-i-general-view"
    },
    {
      id: 3,
      name: t("chatbotAI.guidelines.title"),
      description: t("chatbotAI.guidelines.subtitle"),
      action: Actions.FLORENCE.CHAT_GUIDES,
      icon: "zaia-i-general-view"
    },
    // {
    //   id: 5,
    //   name: "Monaco Algorithms",
    //   description: "",
    //   action: Actions.FLORENCE.MONACO_ALGORITHMS,
    //   icon: "zaia-i-general-view"
    // },
    {
      id: 6,
      name: t("globally.medicalTransductor"),
      description: "",
      action: Actions.FLORENCE.MEDICAL_TRANSDUCTOR,
      icon: "zaia-i-general-view"
    },
    {
      id: 2,
      name: t("globally.patients"),
      description: t("chatbotAI.patients.subtitle"),
      action: Actions.FLORENCE.CHAT_PATIENTS,
      icon: "zaia-i-general-view"
    },
    {
      id: 4,
      name: t("chatbotAI.patientsGuidelines.title"),
      description: t("chatbotAI.patientsGuidelines.subtitle"),
      action: Actions.FLORENCE.CHAT_GUIDES_AND_PATIENTS,
      icon: "zaia-i-general-view"
    },
  ]

  const chatbotViewsProd = [
    {
      id: 1,
      name: t("chatbotAI.general.title"),
      description: t("chatbotAI.general.subtitle"),
      action: Actions.FLORENCE.CHAT_GENERAL,
      icon: "zaia-i-general-view"
    },
    {
      id: 3,
      name: t("chatbotAI.guidelines.title"),
      description: t("chatbotAI.guidelines.subtitle"),
      action: Actions.FLORENCE.CHAT_GUIDES,
      icon: "zaia-i-general-view"
    },
    // {
    //   id: 5,
    //   name: "Monaco Algorithms",
    //   description: "",
    //   action: Actions.FLORENCE.MONACO_ALGORITHMS,
    //   icon: "zaia-i-general-view"
    // },
    {
      id: 6,
      name: t("globally.medicalTransductor"),
      description: "",
      action: Actions.FLORENCE.MEDICAL_TRANSDUCTOR,
      icon: "zaia-i-general-view"
    }
  ]

  const changeView = (view) => {
    dispatch({
      type: "SET_FLORENCE_VIEW",
      florenceView: view,
    });
    selectPatient();
    selectProcess();
    selectPartition();
  } 

  const selectPatient = (patient) => {
    dispatch({
      type: "SET_FLORENCE_PATIENT",
      florencePatient: patient,
    });
  }

  const selectProcess = (process) => {
    dispatch({
      type: "SET_FLORENCE_PROCESS",
      florenceProcess: process,
    });
  }

  const selectPartition = (partition) => {
    dispatch({
      type: "SET_FLORENCE_PARTITION",
      florencePartition: partition,
    });
  }

  const closeAction = () => {
    closeFunc(false);
    setMinimizeActived(false);
    setMaxViewActived(false);
    changeView(0)
  }

  return (
    <S.GenerativeAI
      maxViewActived={maxViewActived}
    >
      {
        florencePartition &&
        <div className="partition-selected">
          <div className="partition-selected__container">
            <div className="partition-selected__point"></div>
            <div className="partition-selected__text">
              {florencePartition.text}
            </div>
          </div>
        </div>
      }
      {
        florenceView === 6 &&
        <TransducerView 
          closeFunc={closeAction}
          maxViewActived={maxViewActived}
          setMaxViewActived={setMaxViewActived}
          minimizeActived={minimizeActived}
          setMinimizeActived={setMinimizeActived}
          changeView={changeView}
        /> 
      }
      {
        florenceView <= 4 &&
        <div className="chatbot">
          <Header 
            closeFunc={closeAction}
            setChatbotViewSelected={changeView}
            chatbotViewSelected={florenceView}
            chatbotViewsProd={chatbotViewsProd}
            processNameSelected={P.dataTranslation(florenceProcess?.processName, language)}

            maxViewActived={maxViewActived}
            setMaxViewActived={setMaxViewActived}
            minimizeActived={minimizeActived}
            setMinimizeActived={setMinimizeActived}
            completeViewActived={completeViewActived}
          />
          <div className="chat-container">
            {
              florenceView === 0 &&
              <ChatbotChooseOptions
                setChatbotViewSelected={changeView}
                chatbotViewsDev={chatbotViewsDev}
                chatbotViewsProd={chatbotViewsProd}
              />
            }
            {
              florenceView === 1 &&
              <ChatbotGeneralCase/>
            }
            {
              florenceView === 2 &&
              <ChatbotPatientCase
                selectedPatient={florencePatient}
                selectPatient={selectPatient}
              />
            }
            {
              florenceView === 3 &&
              <ChatbotProcessCase
                selectedProcess={florenceProcess}
                selectedPartition={florencePartition}
                selectProcess={selectProcess}
                selectPartition={selectPartition}
              />
            }
            {
              florenceView === 4 &&
              <ChatbotGuidanceCase
                selectedPatient={florencePatient}
                selectedProcess={florenceProcess}
                selectedPartition={florencePartition}
                selectPatient={selectPatient}
                selectProcess={selectProcess}
                selectPartition={selectPartition}
              />
            }
          </div>
        </div>
      }
    </S.GenerativeAI>
  );

}

const Header = ({ 
  closeFunc, 
  setChatbotViewSelected,
  chatbotViewSelected,
  chatbotViewsProd,
  processNameSelected,

  maxViewActived,
  setMaxViewActived,
  minimizeActived,
  setMinimizeActived,
  completeViewActived
}) => {

  const {t, i18n: { language }} = useTranslation("global");

  return (
    <S.HeaderDiv
    completeViewActived={completeViewActived}
    >
      <div className="header">
        <div className="header__left">

          {
            !completeViewActived &&
            <>
              <div className="header__close-chat" onClick={() => closeFunc(false)}>
                <i className="header__close-chat-icon icon zaia-icono-cerrar" />
              </div>
              <div className="header__max" onClick={() => setMaxViewActived(!maxViewActived)}>
                {
                  maxViewActived
                  ? <i className="header__max-icon icon zaia-icono-flechas-arriba-abajo ~changeicon" />
                  : <i className="header__max-icon icon zaia-icono-flechas-arriba-abajo ~changeicon" />
                }
              </div>
            </>
          }
          {
            chatbotViewSelected !== 0 &&
            <div className="header__reset-button" onClick={() => setChatbotViewSelected(0)}>{t("chatbotAI.reset")}</div>
          }
        </div>
        <div className="header__right">
          <div className="header__title">
            <img className="header__title-logo" src={florenceLogo} alt={t("globally.chatbotName")} />
            <div className="header__subtitle">{chatbotViewsProd.find((item) => item.id === chatbotViewSelected)?.name}</div>
            {
              processNameSelected &&
              <div className="header__process-name">
                {processNameSelected}
              </div>
            }
          </div>
        </div>
      </div>
    </S.HeaderDiv>
  );
};

const ChatbotChooseOptions = ({ 
  setChatbotViewSelected,
  chatbotViewsDev,
  chatbotViewsProd
}) => {
  const { recordAction } = useRecordNavigation();
  const {doctorData} = useDoctorData();

  const demoUsersProdFilter = () => {
    return ENV === 'production' ? 
      REACT_APP_DOCTOR_1_ID_DEMO === doctorData?.id ||
      REACT_APP_DOCTOR_2_ID_DEMO === doctorData?.id ||
      REACT_APP_DOCTOR_3_ID_DEMO === doctorData?.id ||
      REACT_APP_DOCTOR_4_ID_DEMO === doctorData?.id ||
      REACT_APP_DOCTOR_5_ID_DEMO === doctorData?.id ||
      REACT_APP_DOCTOR_6_ID_DEMO === doctorData?.id
    : true;
  }

  return (
    <S.ChatbotChooseOptions>
      <div className="title">
        <img src={bgChatbot} alt="" className="title__img" />
        <div className="title__text">Select Monaco Mode</div>
      </div>
      <div className="view-options">
        {
          (
            demoUsersProdFilter() ? chatbotViewsDev : chatbotViewsProd
          ).map(
            (view) => {
              return(
                <div 
                  className="view-option"
                  key={view.id}
                >
                  <button 
                    className="view-option__button" 
                    onClick={() => {
                      recordAction(view.action);
                      setChatbotViewSelected(view.id)
                    }}
                  >
                    <div className="view-option__button-text">{view.name}</div>
                    <i className={`view-option__button-icon icon ${view.icon}`} />
                  </button>
                  {
                    view.description &&
                    <div className="view-option__description">
                      {view.description}
                    </div>
                  }
                </div>
              )
            }
          )
        }
      </div>
    </S.ChatbotChooseOptions>
  );
};

export default GenerativeAI;
