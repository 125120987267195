import { gql } from "@apollo/client";

export const QUERY_DRUGS_COMMENT_REPORTS = gql`
  query QUERY_DRUGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz,
    $processId: [Int!]
  ) {
    userToDrugHistory(
      where: {
        created_at: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        isTaken: {
          _eq: false
        }, 
        comment: {
          _is_null: false, 
          _neq: ""
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      id
      comment
      created_at
      delay
      dose
      doseType
      drugId
      userId
      userToDrug {
        drug {
          genericName
        }
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_DRUGS_COMMENT_REPORTS_BY_USER = gql`
  query QUERY_DRUGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
    $userId: uuid,
    $processId: [Int!]
  ) {
    userToDrugHistory(
      where: {
        created_at: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        userId: {
          _eq: $userId
        }, 
        isTaken: {
          _eq: false
        }, 
        comment: {
          _is_null: false, 
          _neq: ""
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      id
      comment
      created_at
      delay
      dose
      doseType
      drugId
      userId
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_DRUGS_COMMENT_REPORTS_BY_USER_NO_DATES = gql`
  query QUERY_DRUGS_COMMENT_REPORTS_BY_USER_NO_DATES(
    $userId: uuid,
    $processId: [Int!]
  ) {
    userToDrugHistory(
      where: {
        userId: {
          _eq: $userId
        }, 
        isTaken: {
          _eq: false
        }, 
        comment: {
          _is_null: false, 
          _neq: ""
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      id
      comment
      created_at
      delay
      dose
      doseType
      drugId
      userId
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
      userToDrug {
        drug {
          genericName
        }
      }
    }
  }
`

export const QUERY_GET_DRUGS_INFO = gql`
  query QUERY_GET_DRUGS {
    drug {
      id
      genericName
      drugNames {
        name
        id
      }
      drugDoses {
        doseTypeVal {
          value
          comment
        }
      }
    }
    enDoseShape {
      value
      comment
    }
    enFrequencyType {
      value
      comment
    }
    enDoseType(order_by: {comment: asc}) {
      comment
      value
    }    
  }
`

export const QUERY_GET_USER_MEDICINES = gql`
  query QUERY_GET_USER_MEDICINES(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    user(where: { id: { _eq: $patientId } }) {
      userToDrugs {
        id
        isFix
        isNotify
        isPeriodic
        quantity
        frequency
        comment
        dose
        doseType
        startTreatment
        endTreatment
        startHour
        daysOfWeek
        displayName
        deleted
        doseShapeValue {
          value
          comment
        }
        userToDrugDates {
          dateTime
        }
        frequencyTypeValue {
          comment
          value
        }
        drug {
          id
          genericName
          drugNames {
            id
            name
          }
        }
        drugDose {
          doseType
          doseTypeVal {
            value
            comment
          }
        }
        userToDrugHistories(
          where: {
            _and: { created_at: { _gte: $startDate, _lte: $finishDate } }
          }
        ) {
          id
          created_at
          delay
          dose
          doseType
          drugId
          comment
          isTaken
        }
      }
    }
  }
`;

export const QUERY_GET_USER_MEDICINES_NO_DATES = gql`
  query QUERY_GET_USER_MEDICINES(
    $patientId: uuid
  ) {
    user(where: { id: { _eq: $patientId } }) {
      userToDrugs {
        id
        isFix
        isNotify
        isPeriodic
        quantity
        frequency
        comment
        dose
        doseType
        startTreatment
        endTreatment
        startHour
        daysOfWeek
        displayName
        doseShapeValue {
          value
          comment
        }
        userToDrugDates {
          dateTime
        }
        frequencyTypeValue {
          comment
          value
        }
        drug {
          id
          genericName
          drugNames {
            id
            name
          }
        }
        drugDose {
          doseType
          doseTypeVal {
            value
            comment
          }
        }
        userToDrugHistories {
          id
          created_at
          delay
          dose
          doseType
          drugId
          comment
          isTaken
        }
      }
    }
  }
`;


export const QUERY_GET_ALL_USER_MEDICINES = gql`
  query QUERY_GET_ALL_USER_MEDICINES(
    $startDate: timestamptz
    $finishDate: timestamptz
    $processIds: [Int!]
  ) {
    userToDrugHistory(
      where: { 
        _and: { 
          created_at: { 
            _gte: $startDate, 
            _lte: $finishDate 
          },
          user: {
            userToProcesses: {
              processId: {
                _in: $processIds
              }
            }
          }
        } 
      }
    ) {
      id
      created_at
      isTaken
      userId
      userToDrug {
        drugId
        drug {
          genericName
        }
      }
    }
  }
`;

export const QUERY_GET_USER_TO_DRUG = gql`
  query QUERY_GET_USER_TO_DRUG($patientId: uuid!) {
    user(where: {id: {_eq: $patientId}}) {
      id
      userToDrugs {
        daysOfWeek
        comment
        displayName
        dose
        doseShape
        doseType
        drugId
        endTreatment
        frequency
        frequencyType
        id
        isFix
        isNotify
        isPeriodic
        quantity
        startHour
        startTreatment
        userId
        drug {
          id
          genericName
          drugNames {
            name
            id
          }
          drugDoses {
            doseTypeVal {
              value
              comment
            }
          }
        }
      }
    }
  }
`;
