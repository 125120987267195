import React, { useContext, useEffect, useRef, useState } from "react";
import * as S from "./ChatbotGeneralCase.style"
import { ThemeContext } from 'styled-components';
import { useLazyQuery } from "@apollo/client";
import { CONSULT_GENERAL_CHAT_IN_FLORENCE_CHATGPT } from "../../../../../../graphql/mutations/Chat";
import useSize from "../../../../../../hooks/useSize";
import { useTranslation } from "react-i18next";
import useDoctorData from "../../../../../../hooks/useDoctorData";
import ZaiaLoadingPoints from "../../../../../zaiaComponents/zaiaLoadingPoints";
import ChatbotInput from "../../chatbotInput";
import Message from "../../message";

const bgChatbot = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/chat-background.svg`

function ChatbotGeneralCase() {

  const footerRef = useRef();
  const { height } = useSize(footerRef);
  const element = document.getElementById("chatbot-container");
  const [t] = useTranslation("global");
  const { doctorData } = useDoctorData();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    patientMessage(values.msg, 'doctor');
    sendBotMessage(values.msg, setSubmitting, resetForm);
    autoScroll();
  };

  const sendBotMessage = async (msg, setSubmitting, resetForm) => {    
    try {
      if(resetForm) resetForm();
      if(setSubmitting) setSubmitting(false);
      sendChatbotMessage(msg);
    } catch (error) {
      console.log(error);
    }
  }


  const patientMessage = (msg, type) => {
    let allMessages = messages;
    allMessages.push(
      {
        id: Date.now(),
        message: msg,
        senderName: `${doctorData.name} ${doctorData.lastname}`,
        type: type
      }
    )
    setMessages([...allMessages])
  }

  const autoScroll = () => {
    if(element) element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  const [chatBotGeneral, {loading: chatBotGeneralLoading}] = useLazyQuery(
    CONSULT_GENERAL_CHAT_IN_FLORENCE_CHATGPT,
    {
      fetchPolicy: "network-only"
    }
  );

  const sendChatbotMessage = async (msg) => {
    const {
      data: {
        consult_chat_gpt,
      },
    } = await chatBotGeneral({
      variables: {
        prompt: `
          
          Usted es un asistente virtual altamente capacitado con experiencia avanzada en conocimientos médicos y atención al paciente.

          Su función principal es ayudar a un médico brindándole respuestas precisas, integrales y contextualmente apropiadas a sus consultas o tareas.
          Utilice todo el contexto y la información de antecedentes disponibles para mejorar la profundidad y relevancia de sus respuestas.
          Presente información de manera estructurada, profesional y fácilmente comprensible, adaptada a las necesidades de un profesional médico.
          Incorporar conocimientos médicos basados   en evidencia, pautas clínicas o mejores prácticas cuando corresponda.
          Evite introducir información irrelevante o especulativa a menos que se le solicite explícitamente para explorar posibilidades o escenarios hipotéticos.
          Si la pregunta o el contexto sugieren ambigüedad, identifique las brechas y proporcione una variedad de enfoques potenciales o solicite información adicional.

          Pregunta: "${msg}"
        `,
      },
    });

    console.log("qwertyyy", consult_chat_gpt);

    if (!chatBotGeneralLoading ) {
      setMessages(
        [
          ...messages,
          {
            id: Date.now(),
            message: consult_chat_gpt.answer,
            senderName: t("globally.chatbotName"),
            type: 'bot',
          }
        ]
      )
      autoScroll()
    }
  }
  
  const [messages, setMessages] = useState([]);

  return (
    <S.ChatbotGeneralCase footerHeight={height}>
      <div className="chatbot-container" id="chatbot-container">
        {
          messages.length === 0 &&
          <div className="chatbot-container__empty">
            <img src={bgChatbot} alt="" className="chatbot-container__empty-image" />
            <div className="chatbot-container__empty-msg">
              {t("chatbotAI.general.letsTalk")}
            </div>
          </div>
        }
        {messages.map((message, index) => (
          <div key={message.id}>
            { 
              <Message
                key={index}
                message={message.message?.replace('0chatgpt', '')}
                senderName={message.senderName}
                option={message.type}
              />
            }
          </div>
        ))}
        { 
          chatBotGeneralLoading
          ? <Message
            senderName={t("globally.chatbotName")}
          >
            <ZaiaLoadingPoints />
          </Message> 
          : null
        }
      </div>
      <div className="chatbot-footer" ref={footerRef}>
        <ChatbotInput
          chatbotDisabled={false}
          handleSubmit={handleSubmit}
        />
      </div>
    </S.ChatbotGeneralCase>
  );

}

export default ChatbotGeneralCase;
