
import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ListOfAlerts = styled.div`
  .table-container {
    /* height: calc(100% - 100px); */
    width: 100%;
    overflow: auto;
    height: calc(100% - 60px);
    /* margin-top: 20px; */
    /* padding: 0 38px; */
    box-sizing: border-box;
    overflow: auto;
    .suggestion-table {
      table-layout: auto;
      border-collapse: collapse;
      width: 100%;
      thead {
        border-bottom: solid #23229D40 0.5px;
      }
      th {
        position: sticky;
        top: 0;
        padding: 10px 20px;
        align-items: center;
        color: #8686A9;
        font-size: 19px;
        font-family: "Nunito Sans";
        font-weight: 400;
        text-align: start;
        background-color: white;
        /* padding-bottom: 15px;         */
      }
      .filter {
        &--program-status {
          /* flex-direction: column; */
          /* display: flex; */
          &__label {
            text-align: end;
          }
          &__space {
            display: flex;
            justify-content: end;
          }
        }
        &__label {
          color: #7D7C8B;
          font-family: Nunito Sans;
          font-size: 13px;
        }
        .patient-order {
          background-color: #F0F0FF;
          width: 206px;
          height: 34px;
          padding: 5px 15px 5px 15px;
          border-radius: 31px;
          align-items: center;
          display: flex;
          border: none;
          outline: none;
          cursor: pointer;
          color: #36369B;
          &--actived {
            background-color: #6C6BCC;
            color: #fff;
          }
          &__icon {
            font-size: 18px;
            color: #3E3DA3;
            opacity: 0.4;
            margin-right: 14.5px;
            &--actived {
              color: #F6F6FF;
            }
          }
          &__text {
            font-family: Nunito Sans;
            font-size: 17px;
            white-space: nowrap;
          }
        }
        .date-order {
          background-color: #F0F0FF;
          width: 206px;
          height: 34px;
          padding: 5px 15px 5px 15px;
          border-radius: 31px;
          align-items: center;
          display: flex;
          border: none;
          outline: none;
          cursor: pointer;
          font-family: Nunito Sans;
          font-size: 17px;
          color: #36369B;
        }
        .program-filter {
          background-color: #F0F0FF;
          width: 206px;
          height: 34px;
          padding: 5px 15px 5px 15px;
          border-radius: 31px;
          align-items: center;
          display: flex;
          border: none;
          outline: none;
          cursor: pointer;
          justify-content: space-between;
          color: #36369B;
          font-family: Nunito Sans;
          font-size: 17px;
          &__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .status-all {
          display: flex;
          align-items: center;
          height: 34px;
          &__icon {
            font-size: 12px;
          }
          &__text {
            font-size: 17px;
          }
        }
        .program-filter {
          background-color: #F0F0FF;
          width: 206px;
          height: 34px;
          padding: 5px 15px 5px 15px;
          border-radius: 31px;
          align-items: center;
          display: flex;
          border: none;
          outline: none;
          cursor: pointer;
          justify-content: space-between;
          color: #36369B;
          font-family: Nunito Sans;
          font-size: 17px;
        }
        &__program {
          
        }
      }
      tbody {
        overflow: auto;
        tr {
          cursor: pointer;
          border-bottom: 1px solid #F6F6FF;
        }
        .patient-email {
          text-align: center;
          color: #526479;
          font-size: 16px;
          background-color: #eef1f4;
          border-radius: 5px;
          width: fit-content;
          margin: auto;
        }
        .patient-program {
          font-size: 16px;
          padding: 0px 10px;
          border-radius: 4px;
          background-color: #F7F7FB;
          font-family: Nunito Sans;
          font-size: 14px;
          color: #3E3E5B;
          width: fit-content;
        }
        .patient-date {
          font-family: Nunito Sans;
          font-size: 16px;
          color: #707582;
        }
        .patient-content {
          display: flex;
          /* margin-left: 20px; */
          /* width: 100%; */
          /* justify-content: center; */
          /* margin: 0 15%; */
          cursor: pointer;
          align-items: center;
          &__info {
            margin-left: 10px;
            &-name-psa {
              color: #1F1F52;
              font-size: 17px;
              font-family: "Nunito Sans";
              text-overflow: ellipsis;
              overflow: hidden;
              height: 1.2em;
              white-space: nowrap;
            }
            &-email {
              margin-top: 5px;
              font-size: 14px;
              color: #707582;
            }
            &-phone {
              font-size: 14px;
              color: #707582;
            }
            &-document {
              font-size: 14px;
              color: #707582;
            }
          }
        }
        .patient-status {
          display: flex;
          align-items: center;
          font-family: Nunito Sans;
          font-size: 16px;
          color: #1F1F52;
          width: 100%;
          padding: 14px 12px;
          background-color: #F6F6FF;
          border-radius: 20px;
          box-sizing: border-box;
          color: #1F1F52;
          &__icon {
            color: #3E3DA3;
            font-size: 13px;
            margin-right: 3px;
          }

        }
      }
      td {
        padding: 15px 20px 5px 20px;
        box-sizing: border-box;
      }
      .last-symptom-card {
        padding: 10px;
        width: 265px;
        height: 55px;
        border-radius: 10.75px;
        background-color: #F3F3FC;
        display: flex;
        margin: auto;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        &__icon {
          color: #36369B;
          font-size: 20px;
        }
        &__info {
          width: calc(100% - 30px);
          margin-left: 10px;
          &-name {
            color: #36369B;
            font-size: 17px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            width: 100%;
          }
          &-date {
            font-size: 14px;
            color: ${"#36369B" + getHexOpacity(0.4)};
            inline-size: max-content;
          }
        }
        /* justify-content: center; */
      }
      .alert-counters {
        display: flex;
        .alert-counter {
          padding-left: 10px;
          padding-right: 20px;
          border-left: 1px solid #D2D7DC;
          display: flex;
          align-items: center;
          &__text {
            margin: 0px 10px 0px 5px;
          }
          &__color {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
          &--first {
            padding-left: 20px;
          }
        }
      }
      .quick-actions-container {
        /* visibility: hidden; */
        display: flex;    
        justify-content: center;
        .quick-action {
          margin: 0 5px;
          width: 61px;
          height: 53px;
          border-radius: 10.75px;
          background-color: #F3F3FC;
          display: flex;
          justify-content: center;
          cursor: pointer;
          &__icon {
            font-size: 17.75px;
            color: #36369B;
            align-items: center;
          }
        }
      }
    }
    .zaia-reaction {
      width: 100%;
      height: 100%;
      display: flex;
    }
    
  }
`;

export const FiltersAlerts = styled.div`

`;


export default ListOfAlerts;