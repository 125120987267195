import React, { useMemo } from "react";
import * as S from "./MedicationFrecuency.style";
import { useTranslation } from "react-i18next";
import ZaiaReaction from "../../../../../../../zaiaComponents/zaiaReaction";

import * as P from "../../../../../../../../utils/pipes";
import ZaiaSelect from "../../../../../../../zaiaComponents/zaiaSelect";
import ZaiaInput from "../../../../../../../zaiaComponents/zaiaInput";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function MedicationFrecuency({
  selectedInterval,
  selectedStartingHour,
  changeFormField,
  frecuencyList,
  goNextInput
}) {

  const {t, i18n: { language }} = useTranslation("global");

  // console.log("you know how it feel", frecuencyList?.map((frecuency) => {
  //   return {
  //     value: dose.value,
  //     text: P.dataTranslation(
  //       dose.comment,
  //       language  
  //     ),
  //   };
  // }));

  const frequenciesList = [
    {
      value: "1",
      text: `${t("globally.every")} ${t("globally.hour")}` 
    },
    {
      value: "2",
      text: `${t("globally.every")} 2 ${t("globally.hours")}` 
    },
    {
      value: "3",
      text: `${t("globally.every")} 3 ${t("globally.hours")}` 
    },
    {
      value: "4",
      text: `${t("globally.every")} 4 ${t("globally.hours")}` 
    },
    {
      value: "6",
      text: `${t("globally.every")} 6 ${t("globally.hours")}` 
    },
    {
      value: "8",
      text: `${t("globally.every")} 8 ${t("globally.hours")}` 
    },
    {
      value: "12",
      text: `${t("globally.every")} 12 ${t("globally.hours")}` 
    },
    {
      value: "24",
      text: `${t("globally.every")} ${t("globally.day")}` 
    },
    {
      value: "48",
      text: `${t("globally.every")} 2 ${t("globally.days")}` 
    },
    {
      value: "96",
      text: `${t("globally.every")} 4 ${t("globally.days")}` 
    },
    {
      value: "168",
      text: `${t("globally.every")} 1 ${t("globally.week")}` 
    },
    {
      value: "336",
      text: `${t("globally.every")} 2 ${t("globally.weeks")}` 
    },
    {
      value: "720",
      text: `${t("globally.every")} 1 ${t("globally.month")}` 
    },
  ]

  return (
    <S.MedicationFrecuency>
      <div className="title">{t("healthAddData.regularIntervals")}</div>
      <div className="doseage-inputs">
        <div className="input-space">
          <div className="input-label">
            {t("globally.chooseInterval")}
          </div>
          <div className="input-space__number-icon">
          </div>
          <ZaiaSelect
            placeholder={t("healthAddData.chooseAnInterval")}
            textColor="#6C6BCC"
            placeholderTextColor="#6C6BCC"
            fontSize={"15px"}
            height={"33px"}
            errorOption={false}
            maxHeightList={"200px"}
            padding={"20px 27px"}
            borderColor={"#6C6BCC"}
            borderRadius={"14px"}
            heightList={"200px"}
            value={selectedInterval.value}
            setValue={(value) => 
              changeFormField(
                [
                  {
                    value: value, 
                    text: frequenciesList.find((frequency) => frequency.value === value).text + ", ", 
                    fieldName: "interval"
                  }
                ]
              )
            }
            items={frequenciesList}
          />
          <div className="input-msg">{t("globally.recieveNotificationEveryDay")}</div>
        </div>
        <div className="input-space input-space--dose">
          <div className="input-label">
            {t("healthAddData.startingHour")}
          </div>
          <ZaiaInput 
            placeholder={t("healthAddData.medication.typeQuantity")}
            type={"time"}
            name="data"
            errorOption={false}
            getText={(e) => {
              if(e) {
                changeFormField(
                [
                  {
                    value: e, 
                    text: e, 
                    fieldName: "startingHour"
                  }
                ]
              )
              } else {
                changeFormField(
                  [
                    {
                      value: null, 
                      text: "", 
                      fieldName: "startingHour"
                    }
                  ]
                )
              }
            }}
            value={selectedStartingHour.value ?? ""}
            padding={"20px"}
            borderRadius={"14px"}
            textColor={"#6C6BCC"}
            borderColor={"transparent"}
            placeholderTextColor={"#878792"}
            fontSize="15px"
            />
        </div>
      </div>
    </S.MedicationFrecuency>
  );
}

export default MedicationFrecuency;
